<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import StatCard from "@/components/dashboard/StatCard.vue";
import API from "@/plugins/axios-method";
import store from "@/state/store";
import DataLoader from "@/components/data-loader.vue";

export default {
  components: {DataLoader, PageHeader, StatCard, Layout},
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: 'Application Dashboard' }],
  },
  data() {
    return {
      url : '/users/statistics/' + store.getters['auth/userId'],
      commercial_count: 0,
      total_deposit: 0,
      total_registration_fees: 0,
      showDataLoader: true
    };
  },
  mounted() {
    this.getStats()
  },
  methods: {
    getStats(){
      API.get(this.url)
        .then(response => {
          if (response.status === 200){
            const statistics = response.data
            this.commercial_count = statistics.users
            this.total_deposit = statistics.deposit
            this.total_registration_fees = statistics.registration
          }
        })
        .finally(() => {
          this.showDataLoader = false
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :show-add-button="false" :title="'Superviseur'" :show-search="false" />
    <section>
      <div class="row">
        <div class="col-12">
          <h5 class="text-decoration-underline mb-3 pb-1">Statistiques Superviseur</h5>
        </div>
      </div>
      <data-loader v-if="showDataLoader"/>
      <div class="row" v-else-if="!showDataLoader">
        <StatCard
            :amount="total_registration_fees"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Frais d\'Inscriptions'"
        />
        <StatCard
            :amount="total_deposit"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Epargne Totale'"
            :to-link="'/epargnes'"
        />
        <StatCard
            :title="'Commerciaux'"
            :to-link="'/utilisateurs'"
            :amount="commercial_count"
            :is-increase="true"
            :percentage="35"
            :icon-bg="'primary'"
            :icon="'user-circle'"
        />
      </div>
    </section>
  </Layout>
</template>